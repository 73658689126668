import Medium from './assets/icons-updated/medium.png';

export const techStack = [

  { name: "AWS", link: "https://seeklogo.com/images/A/amazon-web-services-aws-logo-6C2E3DCD3E-seeklogo.com.png" },
  { name: "Docker", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-plain.svg" },
  { name: "Jenkins", link: "https://www.vectorlogo.zone/logos/jenkins/jenkins-icon.svg" },
  { name: "Kubernetes", link: "https://www.vectorlogo.zone/logos/kubernetes/kubernetes-icon.svg" },
  { name: "Terraform", link: "https://www.vectorlogo.zone/logos/terraformio/terraformio-icon.svg" },
  { name: "Git", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-plain.svg" },
  { name: "Github Action", link: "https://raw.githubusercontent.com/devicons/devicon/ca28c779441053191ff11710fe24a9e6c23690d6/icons/githubactions/githubactions-original.svg" },
  { name: "Python", link: "https://www.vectorlogo.zone/logos/python/python-icon.svg" },
  { name: "MySQl", link: "https://www.vectorlogo.zone/logos/mysql/mysql-icon.svg" },
  { name: "HTML", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg" },
  { name: "CSS", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain.svg" },
  { name: "Javascript", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" },
  { name: "C++", link: "https://seeklogo.com/images/C/c-logo-43CE78FF9C-seeklogo.com.png" },
  { name: "Bash", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bash/bash-original.svg" },
  { name: "Postman", link: "https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg" },

  //{ name: "Dart", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/dart/dart-original.svg" },
  //{ name: "Flutter", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-plain.svg" },
  //{ name: "React Native", link: "https://pagepro.co/blog/wp-content/uploads/2020/03/react-native-logo-884x1024.png" },
  //{ name: "Bootstrap", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain.svg" },
  //{ name: "Redux", link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg" },
  //{ name: "Sass", link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg" },
  //{ name: "Tailwind CSS", link: "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg" },
  //{ name: "React", link: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" },
  //{ name: "Mongo DB", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-plain.svg" },
  //{ name: "Heroku", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain.svg" },
  // { name: "Azure", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-plain.svg" },
  //{ name: "Firebase", link: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg" },
  // { name: "Vim", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vim/vim-original.svg" },
  // { name: "VS Code", link: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg" },
  // {name: "", link: "https://repository-images.githubusercontent.com/59065830/b62be480-45d2-11ea-9989-803db0f9c44d"}
]


export const contactLinks = [
  // { name: 'Stack Overflow', url: 'https://img.icons8.com/external-tal-revivo-color-tal-revivo/40/000000/external-stack-overflow-is-a-question-and-answer-site-for-professional-logo-color-tal-revivo.png', link: 'https://stackoverflow.com/users/11788531/shubham-sharma' },
  { name: 'Twitter', url: 'https://img.icons8.com/?size=50&id=8824&format=png&color=000000', link: 'https://x.com/murti_shubham' },
  { name: 'Github', url: 'https://img.icons8.com/?size=50&id=12599&format=png&color=000000', link: 'https://github.com/shubhammurti' },
  { name: 'LinkedIn', url: 'https://img.icons8.com/?size=46&id=8808&format=png&color=000000', link: 'https://www.linkedin.com/in/shubham-murti-b6486a1aa/' },
  { name: 'Medium', url: 'https://img.icons8.com/?size=50&id=114433&format=png&color=000000', link: 'https://medium.com/@murtishubham007' },

]


export const Certificates = [
  { name: 'AWS Cloud Practitoner', url: 'https://i.ibb.co/gd9JxSb/Badge-22png.png', link: 'https://drive.google.com/file/d/1W5nDIOTnhkGFIxgeI1mWNhy_kgyXFpDr/view?usp=drive_link', description: "Validity: 2024-2027" },
  { name: 'AWS Solution Architect - Associate [In Progress]', url: 'https://sertifier.com/blog/wp-content/uploads/2020/10/certificate-text-samples.jpg', link: 'https://aws.amazon.com/certification/certified-solutions-architect-associate/', description: "Validity: 2024-2026" },
]


export const serviceData = [
  {
    name: 'HSC - 2019',
    schoolname: 'Parag Junior College of Com. & Sci.',
    boardname: 'Maharashtra State Board',
    desc: 'Percentage : 69.23%',
    img: "https://img.icons8.com/?size=100&id=40734&format=png&color=000000"
  },
  {
    name: 'BSc IT - 2022',
    schoolname: 'NES Ratnam College of Com. & Sci.',
    boardname: 'University Of Mumbai',
    desc: `CGPA : 8.14`,
    img: "https://img.icons8.com/?size=100&id=95095&format=png&color=000000"
  },

  {
    name: 'MSc CS - 2024',
    schoolname: 'Ramniranjan Jhunjhunwala College',
    boardname: 'University Of Mumbai',
    desc: `CGPA : 7.41`,
    img: "https://img.icons8.com/?size=100&id=21258&format=png&color=000000"
  },
  


]


export const Blogs = [
  { name: 'My Journey to Becoming a Cloud Engineer', link: 'https://medium.com/@murtishubham007/my-journey-to-becoming-a-cloud-engineer-week-one-37236de8d48f', description: "I’m excited to share that I have embarked on a journey to become a Cloud Engineer." },
  { name: 'AWS Global Infrastructure and EC2 Services — Week Nine',  link: 'https://medium.com/@murtishubham007/exploring-aws-global-infrastructure-and-ec2-services-week-nine-5eb7c4c1bff8', description: "Fascinating world of AWS global infrastructure and explored Amazon EC2 services." },
  { name: 'Terraform Basics to Advanced Concepts — Week Fifteen',  link: 'https://medium.com/@murtishubham007/exploring-terraform-from-basics-to-advanced-concepts-week-fifteen-e95291f3759e', description: "Deep dive, we explored Terraform, an Infrastructure as Code (IaC) tool." },
  { name: '14 AWS Projects: 100-level projects',  link: 'https://dev.to/shubham_murti/14-aws-projects-a-practical-guide-to-my-cloud-engineering-journey-103n', description: "A Practical Guide to My Cloud Engineering Journey" },
  { name: 'AWS with Intermediate Level Projects',  link: 'https://dev.to/shubham_murti/aws-with-intermediate-level-projects-my-journey-through-level-200-projects-3jfp', description: "I've successfully completed eighth, Level 200 Projects " },
  { name: 'SQL Server Native : AWS Project',  link: 'https://dev.to/shubham_murti/sql-server-native-backup-and-restore-on-amazon-rds-aws-project-49m4', description: "SQL Server Native Backup and Restore on Amazon RDS " },
  { name: 'The Cloud Resume Challenge — AWS',  link: 'https://dev.to/shubham_murti/the-cloud-resume-challenge-aws-1h93', description: "The Cloud Resume Challenge is the best project. " },
  { name: 'Serverless Data Processing on AWS',  link: 'https://dev.to/shubham_murti/serverless-data-processing-on-aws-aws-project-2cln', description: "This is to construct a serverless data processing architecture. " },
  { name: 'Automated video monitoring system with AWS IoT & AI/ML',  link: 'https://medium.com/@murtishubham007/build-an-automated-video-monitoring-system-with-aws-iot-and-ai-ml-aws-project-6a7cc4265c81', description: "AWS offers a robust platform for developing cloud-based solutions, designed. " },
]
